<template>
  <div
    class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
  >
    <div class="w-3/4 h-full cursor-pointer" @click="$emit('close')"></div>
    <div class="w-1/4 h-full bg-white rounded-l-2xl p-10 overflow-auto">
      <div class="flex items-center justify-between">
        <h1 class="text-black font-bold text-xl">Add Staff</h1>
        <div
          class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <CloseIcon />
        </div>
      </div>
      <input
        type="file"
        ref="img"
        accept="image/*"
        class="hidden"
        v-on:change="uploadImg"
      />
      <div
        class="bg-gray-300 bg-opacity-30 px-5 py-20 cursor-pointer rounded-full my-5 mx-auto w-3/4 flex items-center justify-center"
        @click="$refs.img.click()"
        v-if="imgPlaceholder"
      >
        <CameraIcon />
      </div>
      <img
        :src="staff.image"
        class="rounded-full w-3/4 h-auto my-5 mx-auto border border-primary p-2"
        v-else
      />
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="createNewStaff" class="text-left mt-10">
          <div class="mb-5">
            <label for="email" class="block mb-2">First Name</label>
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                id="name"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="John"
                v-model="staff.firstname"
              /><br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="email" class="block mb-2">Last Name</label>
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                id="name"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Doe"
                v-model="staff.lastname"
              /><br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="password" class="block mb-2">Password</label>
            <ValidationProvider
              name="password"
              rules="required|min:9|alpha_num"
              v-slot="{ errors }"
            >
              <input
                id="name"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Min 9 Alphanumeric"
                v-model="staff.password"
              /><br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="email" class="block mb-2">Email</label>
            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <input
                id="email"
                type="email"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="example@tos.com"
                v-model="staff.email"
              /><br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="email" class="block mb-2">Phone Number</label>
            <ValidationProvider
              name="phone"
              rules="required|length:11"
              v-slot="{ errors }"
            >
              <input
                id="email"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="07010090023"
                v-model="staff.phone"
              /><br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="email" class="block mb-2">Gender</label>
            <ValidationProvider
              name="Gender"
              rules="required"
              v-slot="{ errors }"
            >
              <select
                v-model="staff.gender"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Gender"
                id="Gender"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              <br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="email" class="block mb-2">Address</label>
            <ValidationProvider
              name="address"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                id="address"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="12 Adankolo Str"
                v-model="staff.address"
              /><br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="email" class="block mb-2">User's roles</label>
            <ValidationProvider
              name="Permissions"
              rules="required"
              v-slot="{ errors }"
            >
              <vSelect
                :options="roles"
                multiple
                :reduce="(user_type_name) => user_type_name.id"
                v-model="staff.staff_role"
                label="user_type_name"
              />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="email" class="block mb-2">User Permissions</label>
            <ValidationProvider
              name="Permissions"
              rules="required"
              v-slot="{ errors }"
            >
              <vSelect
                :options="permissions"
                label="permission_name"
                multiple
                v-model="staff.staff_permissions"
              />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5" v-if="userRole === 'Executive Admin'">
            <label for="email" class="block mb-2">Office Branch</label>
            <ValidationProvider
              name="Branch"
              rules="required"
              v-slot="{ errors }"
            >
              <select
                v-model="staff.branch_id"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Branch"
                id="Branch"
              >
                <option
                  v-for="branch in branches"
                  :key="branch.branch_id"
                  :value="branch.id.toString()"
                >
                  {{ branch.branch_name }}
                </option>
              </select>
              <br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <button
            class="w-full mt-10 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :class="invalid ? 'bg-gray-300' : 'bg-primary'"
            :disabled="invalid && submitting"
          >
            {{ submitting ? "Please wait..." : "Add Staff" }}
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { createStaff, getRoles } from "@/services/staff";
import { uploadToCloudinary } from "@/services/branch";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
    CameraIcon: () => import("@/assets/icons/CameraIcon.vue"),
    vSelect,
  },
  data() {
    return {
      staff: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        gender: "",
        address: "",
        password: "",
        staff_role: [],
        staff_permissions: [],
        branch_id: "",
      },
      submitting: false,
      imgPlaceholder: true,
      roles: [],
    };
  },
  methods: {
    async createNewStaff() {
      this.submitting = true;
      try {
        if (this.userRole !== "Executive Admin") {
          this.staff.branch_id = this.userBranch;
        }
        this.staff.branch_id = this.staff.branch_id.toString();
        await createStaff(this.staff);
        this.$toast.success("New Staff created.");
        this.$emit("created");
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
      this.submitting = false;
    },
    async uploadImg(e) {
      const img = e.target.files[0];

      let formData = new FormData();
      formData.append("upload_preset", "branchImages");
      formData.append("folder", "tos-project");
      formData.append("file", img);
      try {
        const response = await uploadToCloudinary(formData);
        this.staff.image = response.data.url;
        this.imgPlaceholder = false;
      } catch (error) {
        this.$toast.error("Can't upload image at this time, please try again");
        console.log(error);
      }
    },
    async getUserRoles() {
      try {
        const response = await getRoles();
        this.roles = response.data.rows;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    branches() {
      return this.$store.state.branches;
    },
    userBranch() {
      return this.$store.state.userDetail.branch_id;
    },
    userRole() {
      return this.$store.state.userDetail.staffroles[0].user_type_name;
    },
    permissions() {
      let temp = [];
      this.$store.state.permissions.forEach((el) => {
        temp.push({ ...el, assigned: true });
      });
      return temp;
    },
  },
  mounted() {
    this.getUserRoles();
  },
};
</script>
